import React from 'react'

const Footer = () => {
    return (
        <div style={{
            marginTop: 50
        }}>
            <p>created by Ranganath</p>
        </div>
    )
}

export default Footer
